import {useContext, useEffect, useState} from 'react';
import {SSPApi} from '@sdk';
import type {ApplicationContext, SSPLibsGemsRecommendationResponse} from '../../../lib/types';
import {AppContext} from '../../../lib/context';
import {guardAPIRequest} from '../../../lib/helpers/helpers';
import useIsMounted from '../../../lib/hooks/useIsMounted';
import {isBrowser} from '../../../lib/helpers/browserHelpers';
import {RERENDER_DELAY} from '../../../lib/constants';

// TODO: clean up this after we fix re-render issue
declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    Articles: {newestArticles: SSPLibsGemsRecommendationResponse};
  }
}

type State = {isFetching: boolean; articles: SSPLibsGemsRecommendationResponse};

const getNewestArticles = (appInfo?: ApplicationContext['appInfo']) =>
  guardAPIRequest({
    request: () =>
      SSPApi.newestArticles({
        libId: appInfo?.libraryId || '',
        accountId: appInfo?.accountId || '',
        language: appInfo?.settings?.language || 'en',
        size: 5,
        attachUseReferrerToRedirectURL: true,
      }),
    fallback: {newestArticles: []},
    logMessage: 'failed to obtain newest articles',
  });

const useNewestArticles = () => {
  const [state, setState] = useState<State>({
    isFetching: isBrowser() ? Boolean(window?.Articles) === false : true,
    articles: [],
  });

  const isMounted = useIsMounted();

  const context = useContext(AppContext);

  const libId = context?.appInfo?.libraryId || '';
  const accountId = context?.appInfo?.accountId || '';

  useEffect(() => {
    // TODO: clean up this after we fix re-render issue
    setTimeout(() => {
      (async () => {
        if (!isMounted()) return;

        if ([libId, accountId].includes('')) return;

        const newestArticlesResponse =
          window.Articles || (await getNewestArticles(context?.appInfo));

        window.Articles = {newestArticles: newestArticlesResponse?.newestArticles || []};

        setState({isFetching: false, articles: newestArticlesResponse?.newestArticles || []});
      })();
    }, RERENDER_DELAY);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return state;
};

export default useNewestArticles;
