// eslint-disable-next-line no-restricted-imports
import {v4 as uuid4} from 'uuid';
import React, {useEffect, useState} from 'react';
import {contextFactory} from '@shelf/context-factory';
import {isBrowser} from '../helpers/browserHelpers';
import ManageLS from './helpers';

type ContextProps = {
  anonUserId: string;
  sspPageViewedEventId?: string;
  setSSPPageViewedEventId: (eventId: string) => void;
};

const [Provider, useTrackingContext] = contextFactory<ContextProps>('TrackingContext');

const generateAnonId = () => `anon_${uuid4()}`;

const getInitialAnonUserId = (): string => {
  if (!isBrowser()) return '';

  const persisted = ManageLS.get('persist:nextjs');

  if (persisted?.anonUserId) {
    return persisted.anonUserId;
  }

  const newId = generateAnonId();
  ManageLS.set('persist:nextjs', {anonUserId: newId});

  return newId;
};

export const TrackingProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  const [anonUserId, setAnonUserId] = useState(getInitialAnonUserId());
  const [sspPageViewedEventId, setSSPPageViewedEventId] = useState<string>('');

  // Ensure we have a valid ID after component mounts
  useEffect(() => {
    if (!anonUserId && isBrowser()) {
      const newId = generateAnonId();
      setAnonUserId(newId);
      ManageLS.set('persist:nextjs', {anonUserId: newId});
    }
  }, [anonUserId]);

  return (
    <Provider value={{anonUserId, sspPageViewedEventId, setSSPPageViewedEventId}}>
      {children}
    </Provider>
  );
};

export {useTrackingContext};
