import React, {useContext, useRef, useState} from 'react';
import {sortBy} from 'lodash-es';
import type {FC} from 'react';
import type {LanguageCode} from '@shelf/types/lib/l10n';
import type {ApplicationContext} from '../../lib/types';
import {JSXComponentsParser} from '../../lib/replaceWithSSPComponents';
import {AppContext, useUIContext} from '../../lib/context';
import {
  addClass,
  getLanguageName,
  headerLinkHref,
  headerLinkLabel,
  replaceClass,
} from '../../lib/helpers/helpers';
import {handleBodyScrollChange} from '../../lib/helpers/clientSideHelpers';
import {getPortalSettings, getRouteData} from '../../lib/helpers/routeBindings';

export const Header: FC<{
  context: ApplicationContext;
}> = props => {
  const {context} = props;
  const {sidebarMobileVisible, toggleSidebar} = useUIContext();

  const PortalSettings = getPortalSettings(context);
  const RouteData = getRouteData(context);

  const {pathPrefix} = context;
  const [currentLanguage, setCurrentLanguage] = useState(PortalSettings.BasicSettings.language);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const burgerHolderRef = useRef<HTMLSpanElement>(null);
  const logoRef = useRef<HTMLDivElement>(null);
  const searchRef = useRef<HTMLDivElement>(null); // TODO: Rename to searchLabelRef
  const headerLinksRef = useRef<HTMLDivElement>(null);
  const headerLink = PortalSettings.BasicSettings.homePageLink || '';
  const publishedLanguages = PortalSettings.publishedLanguages || [];
  const showLanguageSelector =
    PortalSettings.BasicSettings.showLanguageSelector && publishedLanguages.length > 1;
  const headerHref = headerLinkHref(headerLink) || '';
  const headerLinkText = (headerLink && headerLinkLabel(headerLink)) || '';
  const bindings = {
    ...props,
    ...context,
    HeaderComponentSettings: {
      homeWebsiteText: headerLinkText,
      homeWebsiteHref: headerHref,
      sidebarMobileVisible,
      showLanguageSelector,
      currentLanguageName: getLanguageName(currentLanguage as LanguageCode),
      languagesWithNames: sortBy(
        publishedLanguages.map((currentLang: LanguageCode) => ({
          value: currentLang,
          name: getLanguageName(currentLang),
        })),
        ['value']
      ),
      logoRef,
      headerLinksRef,
      searchRef,
      burgerHolderRef,
      searchInputRef,
      handlers: {
        onBodyScrollChange: handleBodyScrollChange,
        openSidebarHandler: toggleSidebar,
        onLabelClick: () => {
          if (searchInputRef.current) {
            searchInputRef.current.focus();
          }
        },
        onCrossIconClick: (event: React.UIEvent<HTMLElement>) => {
          event.preventDefault();
          event.stopPropagation();
          if (searchInputRef.current) {
            searchInputRef.current.value = '';
          }
        },

        onSubmit: (event: React.FormEvent<EventTarget>): void => {
          const element = event.target as HTMLFormElement;

          if (element) {
            const data: FormData = new FormData(element);
            const term = data.get('term');

            if (!term) {
              event.preventDefault();
            }
          } else {
            event.preventDefault();
          }
        },
        onSearchBlur: () => {
          if (searchInputRef.current) {
            const refs = [searchInputRef, logoRef, headerLinksRef, searchRef, burgerHolderRef];
            refs.forEach(ref => replaceClass(ref));
          }
        },
        onSearchFocus: () => {
          if (searchInputRef.current) {
            const refs = [searchInputRef, logoRef, headerLinksRef, searchRef, burgerHolderRef];
            refs.forEach(ref => addClass(ref));
          }
        },

        onLanguageChange: (event: React.UIEvent<HTMLElement>) => {
          const element = event.currentTarget as HTMLInputElement;
          element.blur();
          setCurrentLanguage(element.value);
          window.location.href = `${pathPrefix ? `${pathPrefix}/` : '/'}${element.value}/`;
        },
      },
    },
    PortalSettings,
    RouteData,
  };
  const headerCode = context.query.templateDefinition?.componentDefinitions.find(
    component => component.name === 'Header'
  );

  return (
    <JSXComponentsParser
      htmlString={headerCode?.jsxDefinition || ''}
      bindings={bindings}
      templateDefinition={{
        cssDefinition: headerCode?.cssDefinition || '',
      }}
    />
  );
};

export function HeaderWrapper(props: any) {
  const context = useContext(AppContext);

  if (!context) {
    return null;
  }

  return <Header {...props} context={context} />;
}
