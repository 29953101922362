import {getAvailableContentLanguageCodes} from '@shelf/l10n';
import type {L10n} from '@shelf/types';
import {isBrowser} from './browserHelpers';
import {uniq} from './helpers';

/**
 * Basically add slash in the end
 */
export function slugToUrl(slug: string | undefined, pathPrefix?: string, lang?: string) {
  let slugWithPrefix = slug;

  if (lang) {
    const pathPrefixLength = pathPrefix ? pathPrefix?.length : 0;
    const localPath = isBrowser()
      ? `${window.location.pathname}/`.substring(pathPrefixLength).split('/')
      : [];

    const hasMissingLang = !isLanguage(lang, localPath[1]);
    const langSlug = `/${lang}${(slugWithPrefix || '').startsWith('/') ? '' : '/'}${slugWithPrefix}`;

    slugWithPrefix = hasMissingLang ? slugWithPrefix : langSlug;
  }

  if (pathPrefix) {
    slugWithPrefix = `${
      pathPrefix.endsWith('/') ? pathPrefix.substring(1) : pathPrefix
    }${slugWithPrefix}`;
  }

  return getSlugWithUniquePathParts(slugWithPrefix);
}

function isLanguage(lang: string, path?: string): boolean {
  if (!path) {
    return false;
  }

  const availableContentLanguageCodes = getAvailableContentLanguageCodes();

  if (availableContentLanguageCodes.includes(path as L10n.LanguageCode)) {
    return Boolean(path === lang);
  }

  const [language, locale, suffix] = path.split('-');

  return Boolean(language === lang && (!locale || locale.length === 2) && !suffix);
}

function getSlugWithUniquePathParts(slug: string | undefined): string {
  if (!slug) {
    return '';
  }

  slug = slug.replace(/\/\//, '/');

  // eslint-disable-next-line sonarjs/anchor-precedence
  const pathParts = slug.replace(/^\/|\/$/g, '').split('/');

  return `/${uniq(pathParts).join('/')}/`.replace(/\/\//, '/');
}
