import React from 'react';
import {times} from 'lodash-es';
import type {JSX} from 'react';

type Props = {
  className?: string;
  itemLineClassName?: string;
  linesCount?: number;
};

export const defaultLinesCount = 3;

//TODO: Replace with `@shelf/basic-components/lib/Preloader` when SSR is supported
const Preloader = (props: Props): JSX.Element => {
  const {linesCount = defaultLinesCount, className, itemLineClassName} = props;

  return (
    <div className={className}>
      {times(linesCount, number => (
        <div className={itemLineClassName} data-testid={'preloader-item'} key={number} />
      ))}
    </div>
  );
};

export default Preloader;
