import React from 'react';
import type {FC} from 'react';
import styles from './DebugData.module.scss';

type Props = {data: Record<string, any>; className?: string};

export const dataTestId = 'DebugData-testid';

const DebugData: FC<Props> = props => {
  const {data, className} = props;

  try {
    const output = JSON.stringify(data, null, 2);

    const classNames = [styles.debugData, className].join(' ');

    return (
      <pre data-testid={dataTestId} className={classNames}>
        <code>{output}</code>
      </pre>
    );
  } catch {
    return <h3>Failed to display DebugData</h3>;
  }
};

export default DebugData;
