import {useEffect} from 'react';
import {useTrackingContext, useUIContext} from './context';
import {trackPageViewParams} from './helpers/helpers';

let isPageViewed = false;

export const TrackPageView = ({
  libraryId,
  accountId,
  gemId,
}: {
  libraryId: string;
  accountId: string;
  gemId?: string;
}) => {
  const {anonUserId, setSSPPageViewedEventId} = useTrackingContext();
  const {isAppInPreviewMode} = useUIContext();

  useEffect(() => {
    if (isPageViewed || isAppInPreviewMode) {
      return;
    }
    isPageViewed = true;

    (async () => {
      const sspPageViewedEventId = await trackPageViewParams({
        anonUserId,
        libraryId,
        accountId,
        gemId,
      });
      setSSPPageViewedEventId(sspPageViewedEventId ?? '');
    })();
  }, [isAppInPreviewMode, anonUserId, libraryId, accountId, gemId, setSSPPageViewedEventId]);

  return null;
};
