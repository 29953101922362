import React from 'react';
import App from 'next/app';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import {staticCDNProvider} from '@shelf/client-helpers';
import {TrackPageView} from 'lib/TrackPageView';
import debugLib from '../lib/debug';
import {logSSRError} from '../lib/helpers/helpers';
import {DataDogLoggers} from '../lib/DatadogLoggers';
import ErrorBoundaryApp from '../components/ErrorBoundary';
import {TrackingProvider, UIContextProvider} from '../lib/context';
import ErrorPage from './404';
import '../styles.scss';

const debug = debugLib('App');

const determineStatusCode = ({getPageAPIError, err, res}) => {
  if (getPageAPIError) return getPageAPIError.status;

  if (err) return err.statusCode;

  if (res) return res.statusCode;

  return null;
};

function MyApp(props) {
  const {Component, pageProps, err, getPageAPIError, resError, statusCode, logEmitter} = props;

  const pageType = pageProps?.query?.pageType ?? {};
  const is404Page = pageType['404'];
  const favicon = pageProps?.query?.appInfo?.settings?.images?.favicon;

  const appInfo = pageProps.query?.appInfo || {};
  const {accountId, libraryId} = appInfo;
  const gemId = pageProps.query?.currentGem?._id;

  if (err || getPageAPIError || (statusCode && statusCode !== 200)) {
    let error;
    const meta = {statusCode, getPageAPIError, err, resError};

    if (getPageAPIError) {
      const messagePrefix = 'getPage API:';

      if (statusCode === 404) {
        error = {message: `${messagePrefix} Library Not Found`, meta, logLevel: 'debug'};
      }
      // 403 - Not Published
      else if (statusCode === 403) {
        error = {message: `${messagePrefix} SSP Not Published`, meta, logLevel: 'debug'};
      }
      // AJV, Shelf's Bad Request HTTP
      else if (statusCode === 400) {
        error = {message: `${messagePrefix} 400th error`, meta, logLevel: 'warn'};
      }
      // Internal Server, AWS and Network errors
      else {
        error = {message: `${messagePrefix} Internal error`, meta, logLevel: 'error'};
      }
    } else if (err) {
      error = {message: 'Next.js error', meta, logLevel: 'error'};
    }
    // statusCode !== 200
    else {
      error = {message: 'Response Next.js error', meta, logLevel: 'error'};
    }

    logSSRError(error, logEmitter);

    const WomanWithGlasses = dynamic(() => import('../components/WomanWithGlasses'));

    return (
      <>
        <Head>
          <title>Not found - Shelf</title>
          <link
            rel="icon"
            href={`${staticCDNProvider()}/images/favicon/favicon.ico`}
            type="image/x-icon"
          />
        </Head>

        <WomanWithGlasses />
      </>
    );
  }

  return (
    <>
      <Head>
        <link
          rel="icon"
          href={favicon ?? `${staticCDNProvider()}/images/favicon/favicon.ico`}
          type="image/x-icon"
        />
      </Head>
      <DataDogLoggers accountId={accountId} gemId={gemId} />
      <UIContextProvider>
        <TrackingProvider>
          {!!libraryId && !!accountId && (
            <TrackPageView libraryId={libraryId} accountId={accountId} gemId={gemId} />
          )}
          {is404Page ? (
            <ErrorBoundaryApp>
              <ErrorPage {...pageProps} />
            </ErrorBoundaryApp>
          ) : (
            <ErrorBoundaryApp>
              <Component {...pageProps} />
            </ErrorBoundaryApp>
          )}
        </TrackingProvider>
      </UIContextProvider>
    </>
  );
}

// Implement getInitialProps directly on MyApp, combining App.getInitialProps with error handling
MyApp.getInitialProps = async appContext => {
  // Get the original props
  const appProps = await App.getInitialProps(appContext);
  const {res, err, getPageAPIError} = appContext.ctx;

  const statusCode = determineStatusCode({getPageAPIError, err, res});

  debug('MyApp.getInitialProps', {
    appProps,
    statusCode,
    hasError: !!err || !!getPageAPIError,
  });

  // Clean up context to avoid circular references
  if (appProps.pageProps?.initialState?.ctx) {
    const {res: res_, req: req_, ...restOfCtx} = appProps.pageProps.initialState.ctx;
    appProps.pageProps.initialState.ctx = restOfCtx;
  }

  return {
    ...appProps,
    statusCode,
    err,
    getPageAPIError,
    logEmitter: res?.logEmitter,
    resError: res?.error,
  };
};

export default MyApp;
