import type {Query} from '../types';
import type {
  ArticlePageRoutesData,
  FolderPageRoutesData,
  HomePageRoutesData,
  NotGemPageRoutesData,
} from './types';
import getDataForHomePageRoute from './home';
import getDataForFolderPageRoute from './folder';
import getDataForArticlePageRoute from './article';
import getDataForArticleDTPageRoute from './article-dt';
import getDataForSearchPageRoute from './search';
import getDataFor404Page from './404';

type GetDataForRouteArgs = {
  query: Query;
};

type DataForRoute =
  | HomePageRoutesData
  | FolderPageRoutesData
  | ArticlePageRoutesData
  | NotGemPageRoutesData;

export const getDataForRoute = (args: GetDataForRouteArgs): DataForRoute => {
  const {query} = args;

  const {pageTypeString} = query;

  if (pageTypeString === 'home') {
    return getDataForHomePageRoute(args);
  }

  if (pageTypeString === 'folder') {
    return getDataForFolderPageRoute(args);
  }

  if (pageTypeString === 'article') {
    return getDataForArticlePageRoute(args);
  }

  if (pageTypeString === 'article-dt') {
    return getDataForArticleDTPageRoute(args);
  }

  if (pageTypeString === 'search') {
    return getDataForSearchPageRoute(args);
  }

  return getDataFor404Page(args);
};
