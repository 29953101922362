import {DDRum, Logger} from '@shelf/client-logger';
import {isBrowser} from './helpers/browserHelpers';

const service = 'shelf-app-ssp';

type DataDogInitParams = {gemId: string; accountId: string};

export const DataDogLoggers = ({gemId, accountId}: Partial<DataDogInitParams>) => {
  if (!isBrowser()) return null;

  return (
    <>
      <Logger
        service={service}
        clientToken={process.env.DATADOG_CLIENT_TOKEN}
        datacenter={process.env.DATADOG_DATACENTER as 'us'}
        accountId={accountId}
        gemId={gemId}
      />
      <DDRum
        service={service}
        clientToken={process.env.DATADOG_RUM_CLIENT_TOKEN}
        applicationId={process.env.DATADOG_RUM_APPLICATION_ID}
        site={process.env.DATADOG_RUM_SITE}
        sessionSampleRate={10}
        accountId={accountId}
        gemId={gemId}
      />
    </>
  );
};
