import React, {useContext} from 'react';
import {NextSeo} from 'next-seo';
import Script from 'next/script';
import type {Query} from '../lib/types';
import {useServerLanguage} from '../lib/i18n';
import {getCustomDomainFromQuery, getPathPrefixFromDomain} from '../lib/helpers/helpers';
import {
  getCssRouteDefinition,
  getJSTemplateDefinition,
  getJSXRouteDefinition,
} from '../lib/replaceWithSSPComponents';
import {slugToUrl} from '../lib/helpers/linkHelpers';
import {AppContext, useTrackingContext, useUIContext} from '../lib/context';
import {getDataForRoute} from '../lib/dataForRoutes';
import JSXRouteParser from '../components/JSXRouteParser';

const NotFoundPage: React.FC<{homeLink: string}> = ({homeLink}) => {
  const context = useContext(AppContext);

  if (!context) {
    return null;
  }

  const htmlString = getJSXRouteDefinition(context) || '';
  const cssString = getCssRouteDefinition(context) || '';
  const globalScript = getJSTemplateDefinition(context) || '';

  return (
    <>
      <JSXRouteParser
        htmlString={htmlString}
        cssString={cssString}
        bindings={{...context, homeLink}}
        templateDefinition={context.query.templateDefinition}
      />
      {/* nosemgrep */}
      {globalScript && (
        <Script
          id={'globalScript'}
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            // nosemgrep
            __html: globalScript,
          }}
        />
      )}
    </>
  );
};

const ErrorPage: React.FC<{query: Query}> = ({query}) => {
  // const lang = get(query, 'appInfo.settings.language');
  const lang = query?.appInfo?.settings?.language;
  const i18n = useServerLanguage(lang);
  const customDomain = getCustomDomainFromQuery(query);
  const pathPrefix = getPathPrefixFromDomain(customDomain);
  const {sidebarMobileVisible} = useUIContext();
  const {anonUserId} = useTrackingContext();
  const title = query?.appInfo?.title || '';
  const homeLink = slugToUrl('/', pathPrefix, lang);

  return (
    <>
      <NextSeo
        title={
          (title
            ? `${title} - ${i18n.t('ssp-app.404.title')}`
            : i18n.t('ssp-app.404.title')) as string
        }
      />
      <AppContext.Provider
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        value={{
          withoutNavigation: true,
          sidebarMobileVisible,
          anonUserId,
          ...getDataForRoute({query}),
        }}
      >
        {query.templateDefinition && <NotFoundPage homeLink={homeLink} />}
      </AppContext.Provider>
    </>
  );
};
export default ErrorPage;
