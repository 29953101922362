import React, {useState} from 'react';
import {contextFactory} from '@shelf/context-factory';

type UIContextType = {
  sidebarMobileVisible: boolean;
  toggleSidebar: () => void;
  setSidebarOpen: (isOpen: boolean) => void;
  isAppInPreviewMode: boolean;
  setIsAppInPreviewMode: (isPreview: boolean) => void;
};

const [Provider, useUIContext] = contextFactory<UIContextType>('UIContext');

export const UIContextProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isAppInPreviewMode, setIsAppInPreviewMode] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(prev => !prev);
  };

  return (
    <Provider
      value={{
        sidebarMobileVisible: sidebarOpen,
        setSidebarOpen,
        toggleSidebar,
        isAppInPreviewMode,
        setIsAppInPreviewMode,
      }}
    >
      {children}
    </Provider>
  );
};

export {useUIContext};
